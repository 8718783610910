import React from 'react'
import "./socialPlatforms.css"
import { Link } from 'react-router-dom';
import RcImage from '../../attributes/RcImage';

export default function SocialPlatforms() {

  const socilas = [
    {id: 1, name: "Facebook", icon: "icons/facebook-icon.png", redirectUrl: "https://www.facebook.com/altoassociation/"},
//{id: 2, name: "Twitter", icon: "icons/twitter-icon.png", redirectUrl: "https://twitter.com/ALTOassociation"},
    {id: 2, name: "Linkedin", icon: "icons/linkedin-icon.png", redirectUrl: "https://www.linkedin.com/company/altoassociation/"},
    {id: 3, name: "Vimeo", icon: "icons/v-icon.png", redirectUrl: "https://vimeo.com/altoassociation"}
  ]

  return (
    <div className='social-platforms-component'>
        <div className='social'>
            {socilas.map((element, index) => (
                <Link to={element.redirectUrl} target="_blank"> <RcImage src={require(`../../${element.icon}`)}></RcImage> </Link>
            ))}
        </div>
    </div>
  )
}